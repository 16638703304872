@import './_theme_colors';
@import './_theme_font';
@import './_theme_custom';
@import './_dates_picker';
//
@import './header';

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
